import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Section, Text, Button, ButtonOutline } from '../../components';
import Responsively from 'responsively';
import { min, max } from '../../common/utils';

const wrapperStyles = css`
  ${Responsively.responsive('margin-top')([0, 0, 0, '-102px'])};
`;

const headerStyles = css`
  ${Responsively.responsive('margin')([
    '100px 0 50px',
    '240px 0 180px',
    '240px 0 180px',
    null,
    '330px 0 230px',
  ])};
`;

const subtitleStyles = css`
  margin-top: 25px;
  max-width: 375px;
`;

const footerStyles = () =>
  css`
    flex-direction: column;
    width: 100%;
    ${min(
      'mobile',
      `
        display: inline-flex;
        width: 375px;
      `,
    )}
  `;

const primaryButtonStyles = css`
  margin-right: 30px;
  ${max(
    'mobile',
    `
        margin-right: 0;
        width: 100%;
        margin-bottom: 15px;
      `,
  )}
`;

const secondaryButtonStyles = css`
  ${max(
    'mobile',
    `
        width: 100%;
      `,
  )}
`;

const platformStyles = ({ theme }) =>
  css`
    margin-top: 30px;
    border-top: 1px solid ${theme.accent.border};
    padding-top: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

export const Wrapper = styled(Section)(wrapperStyles);

export const Header = styled('header')(headerStyles);

export const Subtitle = styled(Text)(subtitleStyles);

export const Footer = styled('footer')(footerStyles);

export const ButtonWrapper = styled('div')();

export const PrimaryButton = styled(Button)(primaryButtonStyles);

export const SecondaryButton = styled(ButtonOutline)(secondaryButtonStyles);

export const Platforms = styled('div')(platformStyles);
