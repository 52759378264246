import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Container } from '../Grid';

const baseStyles = ({ background, theme }) =>
  css`
    width: 100%;
    background: ${theme.section[background]};
    margin: 0 auto;
    position: relative;
  `;

const SectionStyles = styled('div')(baseStyles);

/**
 * Section component
 */
const Section = ({
  background = 'light',
  justifyContent = 'space-between',
  alignItems = 'stretch',
  children,
  ...props
}) => (
  <SectionStyles background={background} {...props}>
    <Container justifyContent={justifyContent} alignItems={alignItems}>
      {children}
    </Container>
  </SectionStyles>
);

Section.propTypes = {
  /**
   * Children which will be passed
   */
  children: PropTypes.node.isRequired,
  /**
   * Define section background
   */
  background: PropTypes.oneOf(['light', 'neutral', 'dark']),
  /**
   * Define container width
   */
  justifyContent: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
    'initial',
    'inherit',
  ]),
  /**
   * Define container width
   */
  alignItems: PropTypes.oneOf([
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'baseline',
    'initial',
    'inherit',
  ]),
};

Section.defaultProps = {
  kind: 'default',
  background: 'light',
  justifyContent: 'space-between',
  alignItems: 'stretch',
};

export default Section;
