import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Icon from '../Icons/Icons';
import { hexToRgb } from '../../common/utils';

const checkStyles = ({ theme }) =>
  css`
    background: ${hexToRgb(theme.accent.brand, 0.1)};
    min-width: 24px;
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.radius.circle};
    margin-right: 10px;
  `;

const CheckStyles = styled('span')(checkStyles);

/**
 * Check component
 */
const Check = () => (
  <CheckStyles>
    <Icon kind="check" size={13} color="#2f7aff" />
  </CheckStyles>
);

export default Check;
