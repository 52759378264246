import React, { useState } from 'react';
import { ScrollingProvider } from 'react-scroll-section';
import { ThemeProvider } from 'emotion-theming';
import get from 'lodash.get';
import { Global } from '@emotion/core';
import useFetch from '../common/useFetch';
import theme from '../common/theme';
import { scaffoldingStyles } from '../common/scaffoldingStyles';
import { Navigation } from '../components';
import {
  Applications,
  Contact,
  Intro,
  KeyFeatures,
  Overview,
  Testimonials,
  UseCases,
} from '../sections';

const Home = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const { sections } = useFetch();
  const navigation = get(sections, 'navigation');
  const intro = get(sections, 'intro');
  const keyFeatures = get(sections, 'features');
  const overview = get(sections, 'overview');
  const testimonials = get(sections, 'testimonials');
  const useCases = get(sections, 'useCases');
  const applications = get(sections, 'applications');
  const contact = get(sections, 'contact');

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <ScrollingProvider scrollBehavior="smooth">
          <Global styles={scaffoldingStyles} />
          <Navigation data={navigation} />
          <Intro data={intro} setCurrentTab={setCurrentTab} />
          <KeyFeatures data={keyFeatures} />
          <Overview data={overview} />
          <Testimonials data={testimonials} />
          <UseCases
            data={useCases}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          <Applications data={applications} />
          <Contact data={contact} />
        </ScrollingProvider>
      </React.Fragment>
    </ThemeProvider>
  );
};

export default Home;
