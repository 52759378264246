import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { min, max } from '../../common/utils';
import { Section, Text } from '../../components';

const wrapperStyles = css`
  margin-top: 40px;
  padding: 60px 0;
  ${min(
    'tablet',
    `
      padding: 100px 0;
    `,
  )}
`;

const featureStyles = css`
  text-align: center;
  margin-bottom: 30px;
  ${min(
    'tablet',
    `
      text-align: left;
      margin-bottom: 0;
    `,
  )}
`;

const figureStyles = ({ theme }) =>
  css`
    width: 130px;
    height: 130px;
    border-radius: ${theme.radius.circle};
    background: ${theme.accent.white};
    box-shadow: ${theme.boxShadow.raised};
    margin: 0 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${max(
      'tablet',
      `
        margin-left: auto;
        margin-right: auto;
      `,
    )}
  `;

const featureTextStyles = ({ theme: { transition, link } }) => css`
  margin: 10px 0 0;

  a {
    color: ${link.primary.color};
    transition: ${transition.default};
    &:active,
    &:focus,
    &:hover {
      color: ${link.primary.hover};
      text-decoration: none;
    }
  }
`;

export const Wrapper = styled(Section)(wrapperStyles);

export const Feature = styled('article')(featureStyles);

export const Figure = styled('figure')(figureStyles);

export const FeatureText = styled(Text)(featureTextStyles);
