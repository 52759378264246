import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { min, max, rem, hexToRgb } from '../../common/utils';
import { Section, Image, Text, Link } from '../../components';
import triangle from '../../assets/images/triangle.svg';

const wrapperStyles = () =>
  css`
    display: flex;
    width: 100%;
    padding: 60px 0;
    margin: 0;
    position: relative;
    ${min(
      'tablet',
      `
        padding: 100px 0;
      `,
    )}
  `;

const featuresStyles = ({ theme }) =>
  css`
    padding: 30px;
    margin: 80px 0 0;
    background: ${theme.contact.features};
    border-radius: ${theme.radius.default};
    line-height: 2;
    position: relative;
    max-width: 480px;
    margin: 30px auto;
    ${min(
      'tablet',
      `
        max-width: 360px;
        margin: 60px 0 30px;
        &:before {
          content: '';
          background-image: url(${triangle});
          width: 256px;
          height: 258px;
          background-size: cover;
          position: absolute;
          right: -122px;
          top: -3px;
        }
      `,
    )}
    ${min(
      'desktop',
      `
        padding: 30px 30px 30px 60px;
        max-width: 480px;
        margin: 110px 0 30px;
      `,
    )}

    p:last-of-type {
      margin-bottom: 0;
    }
  `;

const checkStyles = ({ theme }) =>
  css`
    background: ${hexToRgb(theme.accent.brand, 0.1)};
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.radius.circle};
    margin-right: 10px;
  `;

const formStyles = ({ theme }) =>
  css`
    background: ${theme.form.background};
    padding: 30px;
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.boxShadow.raised};
    ${max(
      'tablet',
      `
        max-width: 480px;
        margin: 0 auto;
      `,
    )}
  `;

const formHeaderStyles = () =>
  css`
    margin-bottom: 30px;
  `;

const formFooterStyles = ({ theme }) =>
  css`
    margin: 30px -30px -30px;
    background: ${theme.form.footer};
    padding: 15px 30px 10px;
    border-bottom-left-radius: ${theme.radius.default};
    border-bottom-right-radius: ${theme.radius.default};
  `;

const formFooterLinkStyles = ({ theme }) =>
  css`
    font-size: ${rem(theme.font.size.mini)};
  `;

const footerStyles = ({ theme }) =>
  css`
    background: ${theme.contact.footer};
    color: ${theme.font.color.light};
    width: 100%;
    margin: 0;
    padding-top: 10px;
    position: relative;
  `;

const footerMetaStyles = () =>
  css`
    ${max(
      'mobile',
      `
        width: 100%;
        text-align: center;
      `,
    )}
  `;

const footerLinkStyles = () =>
  css`
    ${max(
      'mobile',
      `
        margin: 0 auto;
      `,
    )}
  `;

const itenLogoStyles = () =>
  css`
    margin: 0 auto 15px;
    ${min(
      'mobile',
      `
        margin: 0 0 0 10px;
      `,
    )}
  `;

export const Wrapper = styled(Section)(wrapperStyles);
export const Features = styled('div')(featuresStyles);
export const Check = styled('span')(checkStyles);
export const Form = styled('form')(formStyles);
export const FormHeader = styled('header')(formHeaderStyles);
export const FormFooter = styled('footer')(formFooterStyles);
export const FormFooterLink = styled(Link)(formFooterLinkStyles);
export const Footer = styled('footer')(footerStyles);
export const FooterMeta = styled(Text)(footerMetaStyles);
export const FooterLink = styled(Link)(footerLinkStyles);
export const ItenLogo = styled(Image)(itenLogoStyles);
