import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link as RouterLink } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';

const baseStyles = ({ theme }) => css`
  font-family: ${theme.font.semiBold};
  font-weight: normal;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  transition: ${theme.transition.default};
  display: inline-block;
  border: 1px solid transparent;
  border-radius: ${theme.radius.default};
  cursor: pointer;
  outline: none;
  position: relative;
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    /* stylelint-disable-next-line property-no-unknown */
    user-selectable: none;
  }

  svg {
    position: relative;
    fill: currentColor;
    margin-right: 5px;
  }
`;

const blockStyles = ({ isBlock }) =>
  isBlock &&
  css`
    display: block;
    width: 100%;
  `;

const AnchorButtonStyles = styled('a', { shouldForwardProp: isPropValid })(
  baseStyles,
  blockStyles,
);
const PlainButtonStyles = styled('button', { shouldForwardProp: isPropValid })(
  baseStyles,
  blockStyles,
);
const RouterLinkButtonStyles = styled(RouterLink, {
  shouldForwardProp: isPropValid,
})(baseStyles, blockStyles);

/**
 * Button base component
 */
const ButtonBase = ({ to, onClick, children, isBlock, ...props }) => {
  if (!to)
    return (
      <PlainButtonStyles onClick={onClick} isBlock={isBlock} {...props}>
        {children}
      </PlainButtonStyles>
    );

  return to.match(/^https?:\/\//) ? (
    <AnchorButtonStyles href={to} isBlock={isBlock} {...props}>
      {children}
    </AnchorButtonStyles>
  ) : (
    <RouterLinkButtonStyles to={to} isBlock={isBlock} {...props}>
      {children}
    </RouterLinkButtonStyles>
  );
};

ButtonBase.propTypes = {
  /**
   * Define path to next screen, or page
   */
  to: PropTypes.node,
  /**
   * Provide option to pass onClick
   */
  onClick: PropTypes.func,
  /**
   * Children which will be passed
   */
  children: PropTypes.any.isRequired,
  /**
   * Span button to full width
   */
  isBlock: PropTypes.bool,
};

ButtonBase.defaultProps = {
  to: null,
  onClick: () => {},
  isBlock: false,
};

export default ButtonBase;
