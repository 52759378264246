import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { min, max } from '../../common/utils';
import { Section, Heading, ButtonOutline, Image } from '../../components';

const wrapperStyles = ({ theme }) =>
  css`
    display: flex;
    width: 100%;
    padding: 60px 0;
    margin: 0;
    position: relative;
    ${min(
      'tablet',
      `
        padding: 100px 0;
      `,
    )}
  `;

const demoImageStyles = ({ theme }) =>
  css`
    box-shadow: ${theme.boxShadow.raised};
    ${max(
      'tablet',
      `
        display: none;
      `,
    )}
  `;

const headerStyles = css`
  align-self: center;
  width: 100%;
  ${max(
    'tablet',
    `
        text-align: center;
      `,
  )}
`;

const titleStyles = css`
  margin-bottom: 40px;
`;

const buttonStyles = css`
  ${max(
    'mobile',
    `
        width: 100%;
      `,
  )}
`;

export const Wrapper = styled(Section)(wrapperStyles);

export const DemoImage = styled(Image)(demoImageStyles);

export const Header = styled('header')(headerStyles);

export const Title = styled(Heading)(titleStyles);

export const DemoButton = styled(ButtonOutline)(buttonStyles);
