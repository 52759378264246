/** @jsx jsx */
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';
import { Container } from '../Grid';
import Logo from '../Logo';

const navigationStyles = css`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
  margin-top: 30px;
  background: rgba(251, 252, 255, 0.9);
  padding: 15px 0;
`;

const NavigationStyles = styled('header')(navigationStyles);

/**
 * Navigation alternate component
 */
const NavigationAlternate = () => (
  <NavigationStyles>
    <Container alignItems="center">
      <Logo />
    </Container>
  </NavigationStyles>
);

export default NavigationAlternate;
