/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Section } from 'react-scroll-section';
import {
  Container,
  Heading,
  Text,
  Button,
  Input,
  Textarea,
  Column,
  Check,
} from '../../components';
import {
  Wrapper,
  Features,
  Form,
  FormHeader,
  FormFooter,
  FormFooterLink,
  Footer,
  FooterMeta,
  FooterLink,
  ItenLogo,
} from './Contact.styles';
import { mb20, mt20, mt30 } from '../../common/classes';
import logo from '../../assets/images/iten-logo.svg';

/**
 * Contact section
 */
const Contact = ({ data }) => {
  return data ? (
    <Section id="contact">
      <Wrapper background="dark">
        <Column span={[12, 12, 6, 5]}>
          <Heading element="h2" color="light">
            {data.title}
          </Heading>
          <Features>
            {data.bulletPoints.map(item => (
              <Text weight="semiBold" key={item.name}>
                <Check />
                {item.name}
              </Text>
            ))}
          </Features>
        </Column>
        <Column span={[12, 12, 6, 5]}>
          <Form
            name="rdesktop-landing-page"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/success"
          >
            <FormHeader>
              <Text size="large" weight="semiBold">
                {data.formTitle}
              </Text>
              <Text>{data.formDescription}</Text>
            </FormHeader>
            <Text size="mini" color="neutral">
              All fields are required, unless indicated otherwise.
            </Text>

            <input
              type="hidden"
              name="form-name"
              value="rdesktop-landing-page"
            />
            <Input
              type="text"
              name="Name"
              placeholder="Your full name"
              required
              css={[mt20, mb20]}
            />
            <Input
              type="email"
              name="Email"
              placeholder="Email address"
              required
              css={mb20}
            />
            <Input
              type="tel"
              pattern="^[0-9-+s()]*$"
              name="Phone Number"
              placeholder="Contact number"
              required
              css={mb20}
            />
            <Textarea
              name="Message"
              placeholder="Message (optional)"
              css={mb20}
            />

            <Button type="submit" isBlock css={mt30}>
              {data.formButton}
            </Button>
            <FormFooter>
              <Text color="neutral" size="mini">
                By sending this inquiry I accept{' '}
                <FormFooterLink
                  to="/terms-of-services"
                  kind="secondary"
                  target="_blank"
                  rel="noopener"
                >
                  Terms of Service and Privacy Policy
                </FormFooterLink>{' '}
              </Text>
            </FormFooter>
          </Form>
        </Column>
      </Wrapper>
      <Footer>
        <Container justifyContent="flex-start">
          <FooterMeta color="neutral" size="mini">
            &copy; All rights reserved • Venture by
          </FooterMeta>
          <FooterLink to="http://www.iten.rs" target="_blank" rel="noopener">
            <ItenLogo src={logo} alt="Logo of Iten Engineering" size={130} />
          </FooterLink>
        </Container>
      </Footer>
    </Section>
  ) : null;
};

Contact.propTypes = {
  /**
   * Data passed from Contenetful
   */
  data: PropTypes.object,
};

Contact.defaultProps = {
  data: null,
};

export default Contact;
