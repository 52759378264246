import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { min, max } from '../../common/utils';
import { Text as T, Heading } from '../../components';

const wrapperStyles = ({ theme }) =>
  css`
    display: flex;
    width: 100%;
    padding: 60px 0;
    margin: 40px auto 0;
    background: ${theme.section.neutral};
    position: relative;
    ${min(
      'tablet',
      `
        padding: 100px 0;
      `,
    )}
  `;

const contentStyles = css`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 45px;
  }
  ${max(
    'tablet',
    `
        flex-direction: column;
        text-align: center;
      `,
  )}
`;

const figureStyles = ({ theme }) =>
  css`
    min-width: 160px;
    min-height: 160px;
    border-radius: ${theme.radius.circle};
    background: ${theme.accent.white};
    box-shadow: ${theme.boxShadow.raised};
    margin: 0 40px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    ${max(
      'tablet',
      `
        margin: 0 auto 30px;
      `,
    )}
  `;

const headerStyles = css`
  margin: 10px 0 0;
`;

const titleStyles = css`
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 0 auto 45px;
  ${min(
    'tablet',
    `
      margin: 0 0 0 200px;
    `,
  )}
`;

const textStyles = css`
  margin: 0;
  line-height: 1.8;
`;

export const Wrapper = styled('section')(wrapperStyles);

export const Content = styled('div')(contentStyles);

export const Figure = styled('figure')(figureStyles);

export const Header = styled('header')(headerStyles);

export const Title = styled(Heading)(titleStyles);

export const Text = styled(T)(textStyles);
