/**
 * Classes represent microsocopic styles which are used for additional tweaking.
 * Potential usage is with `cx()` from Emotion package.
 */
import { css } from '@emotion/core';
import theme from './theme';

// Margins
export const mt20 = css`
  margin-top: 20px;
`;

export const mt30 = css`
  margin-top: 30px;
`;

export const mr15 = css`
  margin-right: 15px;
`;

export const mb20 = css`
  margin-bottom: 20px;
`;

export const ma = css`
  margin: auto;
`;

// Display
export const showMinDesktop = css`
  display: none;
  @media screen and (min-width: ${theme.grid.breakpoints.desktop / 16}em) {
    display: block;
  }
`;

export const showMinTablet = css`
  display: none;
  @media screen and (min-width: ${theme.grid.breakpoints.tablet / 16}em) {
    display: block;
  }
`;

export const df = css`
  display: flex;
`;
