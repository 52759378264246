import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { Home, Terms, NotFound, Success } from './pages';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} />} />
      <Route exact path="/terms-of-services" component={Terms} />} />
      <Redirect exact from="/privacy-policy" to="/terms-of-services" />
      <Route exact path="/success" component={Success} />} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default App;
