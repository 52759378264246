import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { min } from '../../common/utils';
import { Section, Text, Link } from '../../components';

const wrapperStyles = css`
  padding: 60px 0 0;
  ${min(
    'tablet',
    `
      text-align: center;
      padding: 90px 0 0;
    `,
  )}
`;

const headerStyles = css`
  margin: 0 auto 60px;
  ${min(
    'tablet',
    `
      max-width: 670px;
      margin-bottom: 80px;
    `,
  )}
`;

const subheadingStyles = css`
  margin-top: 20px;
  margin-bottom: 0;
`;

const overviewListStyles = css`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const overviewItemStyles = css`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  text-align: left;
  ${min(
    'mobile',
    `
      width: 50%;
      margin-bottom: 40px;
    `,
  )}
  ${min(
    'tablet',
    `
      width: 33.3333%;
    `,
  )}
  ${min(
    'desktop',
    `
      width: 25%;
    `,
  )}

  svg {
    min-width: 36px;
    margin-right: 15px;
  }

  p {
    margin: 0;
    ${min(
      'mobile',
      `
      margin: 0 15px 0 0;
    `,
    )}
  }
`;

const linkStyles = css`
  margin: 20px auto 50px;
  ${min(
    'tablet',
    `
      margin: 40px auto 50px;
    `,
  )}

  &:hover {
    svg {
      transform: rotate(90deg);
    }
  }

  svg {
    margin-left: 5px;
    top: 3px;
  }
`;

export const Wrapper = styled(Section)(wrapperStyles);

export const Header = styled('header')(headerStyles);

export const Subheading = styled(Text)(subheadingStyles);

export const OverviewList = styled('ul')(overviewListStyles);

export const OverviewItem = styled('li')(overviewItemStyles);

export const ScrollToLink = styled(Link)(linkStyles);
