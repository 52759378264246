import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import { rem, min, max } from '../../../common/utils';
import { Link as A, Icon } from '../../../components';

const icons = [
  <Icon kind="use-case-government" size={48} />,
  <Icon kind="use-case-learning" size={48} />,
  <Icon kind="use-case-support" size={56} />,
  <Icon kind="use-case-healthcare" size={46} />,
];

const listStyles = ({ theme }) =>
  css`
    list-style: none;
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid ${theme.accent.border};
    width: 100%;
  `;

const itemStyles = css`
  ${max(
    'tablet',
    `
      width: 25%;
    `,
  )}

  &:hover {
    span {
      transform: translateY(0);
    }
  }
`;

const linkStyles = ({ theme, isActive }) => css`
  color: ${isActive ? theme.font.color.brand : theme.font.color.neutral};
  position: relative;
  padding: 0 10px 15px;
  display: block;
  margin: 0 auto;
  &:hover,
  &:focus {
    color: ${theme.font.color.brand};
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
    transition: ${theme.transition.default};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: ${isActive ? theme.font.color.brand : 'transparent'};
  }

  p {
    display: none;
  }

  ${min(
    'tablet',
    `
      padding: 0 30px 30px;
      display: inline-block;
      margin: 0;

      p {
        display: block;
        font-size: ${rem(theme.font.size.medium)};
        margin: 10px 0 0;
      }
    `,
  )}
`;

const iconWrapperStyles = ({ theme, isActive }) =>
  css`
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 15px;
    transform: translateY(${isActive ? 0 : '15px'});
    transition: ${theme.transition.medium};
    ${min(
      'tablet',
      `
        width: 96px;
        height: 96px;
        border-radius: ${theme.radius.circle};
        background: ${isActive ? theme.accent.lightAccent : 'transparent'};
        margin-bottom: 0;
      `,
    )}

    svg {
      fill: ${isActive ? theme.font.color.brand : theme.font.color.neutral};
    }
  `;

const List = styled('ul')(listStyles);
const Item = styled('li')(itemStyles);
const Link = styled(A)(linkStyles);
const IconWrapper = styled('span')(iconWrapperStyles);

/**
 * Tab Navigation
 */
const TabNavigation = ({ currentTab, setCurrentTab, categories }) => (
  <List>
    {[...new Array(4)].map((_, index) => (
      <Item key={index}>
        <Link
          isActive={currentTab === index}
          weight="semiBold"
          onClick={() => setCurrentTab(index)}
        >
          <IconWrapper isActive={currentTab === index}>
            {icons[index]}
          </IconWrapper>
          <p>{categories[index].title}</p>
        </Link>
      </Item>
    ))}
  </List>
);

TabNavigation.propTypes = {
  /**
   * Indicate which tab is active on use case section
   */
  currentTab: PropTypes.number.isRequired,
  /**
   * Set active tab on use cases section
   */
  setCurrentTab: PropTypes.func.isRequired,
  /**
   * Data passed from Contentful
   */
  categories: PropTypes.array.isRequired,
};

TabNavigation.defaultProps = {
  data: null,
};

export default TabNavigation;
