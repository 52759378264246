import memoize from 'memoize-one';
import Responsively from 'responsively';
import { css } from '@emotion/core';
import theme from './theme';

Responsively.configureBreakpoints([
  theme.grid.breakpoints.mobile,
  theme.grid.breakpoints.tablet,
  theme.grid.breakpoints.desktop,
  theme.grid.breakpoints.hd,
]);

export const rem = px => `${px / 16}rem`;

// Convert hexadecimal color value to rgba
export const hexToRgb = memoize((hex, alpha = 1) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16,
      )}, ${alpha})`
    : null;
});

export const min = memoize((width, content) => {
  if (
    width !== 'mobile' &&
    width !== 'tablet' &&
    width !== 'desktop' &&
    width !== 'hd'
  ) {
    // eslint-disable-next-line no-console
    console.error(
      "Error: Wrong breakpoint is passed to max() function. You can use ether 'mobile', 'tablet', 'desktop' or 'hd' arguments.",
    );
  }

  return css`
    @media screen and (min-width: ${theme.grid.breakpoints[width] / 16}em) {
      ${content}
    }
  `;
});

export const max = memoize((width, content) => {
  if (
    width !== 'mobile' &&
    width !== 'tablet' &&
    width !== 'desktop' &&
    width !== 'hd'
  ) {
    // eslint-disable-next-line no-console
    console.error(
      "Error: Wrong breakpoint is passed to max() function. You can use ether 'mobile', 'tablet', 'desktop' or 'hd' arguments.",
    );
  }

  return css`
    @media screen and (max-width: ${theme.grid.breakpoints[width] / 16}em) {
      ${content}
    }
  `;
});
