/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { SectionLink } from 'react-scroll-section';
import useInterval from '../../common/useInterval';
import { min } from '../../common/utils';
import Heading from '../Heading';
import Text from '../Text';
import Icon from '../Icons/Icons';
import Image from '../Image';

import learning from '../../assets/images/use-cases/learning.jpg';
import government from '../../assets/images/use-cases/government.jpg';
import healthcare from '../../assets/images/use-cases/medicine.jpg';
import itSupport from '../../assets/images/use-cases/it-support.jpg';

const baseStyles = ({ theme }) =>
  css`
    display: block;
    width: 100%;
    height: 700px;
    position: relative;
    z-index: 2;
    border-bottom-left-radius: ${theme.radius.default};
    border-bottom-right-radius: ${theme.radius.default};
    background-color: ${theme.scaffolding.background};
    overflow: hidden;
    margin: 0;
    ${min(
      'hd',
      `
        height: 839px;
      `,
    )}
  `;

const imageStyles = ({ theme: { transition }, currentSlide }) =>
  css`
    position: absolute;
    top: 0;
    transition: ${transition.large};
    opacity: 0;
    ${currentSlide ? 'opacity: 1' : 'opacity: 0'};
  `;

const figcaptionStyles = ({ theme: { transition } }) =>
  css`
    cursor: pointer;
    position: absolute;
    left: 40px;
    right: 40px;
    bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      transition: ${transition.default};
    }

    &:hover {
      svg {
        transform: rotate(90deg);
      }
    }
  `;

const headingStyles = () =>
  css`
    text-transform: uppercase;
    letter-spacing: 0.03em;
  `;

const descriptionStyles = () =>
  css`
    opacity: 0.7;
    margin: 5px 0 0;
    max-width: 80%;
  `;

const CarouselStyles = styled('figure')(baseStyles);

const CarouselImage = styled(Image)(imageStyles);

const Figcaption = styled('figcaption')(figcaptionStyles);

const Title = styled(Heading)(headingStyles);

const Description = styled(Text)(descriptionStyles);

/**
 * Carousel component
 */
const Carousel = ({ data, setCurrentTab, ...props }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { slideOne, slideTwo, slideThree, slideFour } = data;
  const handleClick = link => {
    setCurrentTab(currentSlide);
    link.onClick();
  };

  useInterval(() => {
    currentSlide < 3 ? setCurrentSlide(currentSlide + 1) : setCurrentSlide(0);
  }, 5000);

  return (
    <CarouselStyles {...props}>
      <CarouselImage
        src={government}
        alt={slideTwo}
        currentSlide={currentSlide === 0}
      />
      <CarouselImage
        src={learning}
        alt={slideOne}
        currentSlide={currentSlide === 1}
      />
      <CarouselImage
        src={itSupport}
        alt={slideFour}
        currentSlide={currentSlide === 2}
      />
      <CarouselImage
        src={healthcare}
        alt={slideThree}
        currentSlide={currentSlide === 3}
      />
      <SectionLink section="use-cases">
        {link => (
          <Figcaption onClick={() => handleClick(link)}>
            <header>
              <Title element="h6" color="light">
                R Desktop Use case
              </Title>
              <Description color="light" size="mini">
                {currentSlide === 0 && slideOne}
                {currentSlide === 1 && slideTwo}
                {currentSlide === 2 && slideThree}
                {currentSlide === 3 && slideFour}
              </Description>
            </header>
            <Icon kind="arrow-right" size={28} color="white" />
          </Figcaption>
        )}
      </SectionLink>
    </CarouselStyles>
  );
};

Carousel.propTypes = {
  /**
   * Carousel slides content
   */
  data: PropTypes.object.isRequired,
  /**
   * Set active tab on use cases section
   */
  setCurrentTab: PropTypes.func.isRequired,
};

export default Carousel;
