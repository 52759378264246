/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Container, Image } from '../../components';
import {
  Wrapper,
  Title,
  Text,
  Figure,
  Content,
  Header,
} from './Testimonials.styles';
import apporto from '../../assets/images/testimonials/apporto.jpg';
import sunhillassetspccltd from '../../assets/images/testimonials/sunhillassetspccltd.png';

/**
 * Testimonials section
 */
const Testimonials = ({ data }) => {
  const images = {
    apporto,
    sunhillassetspccltd,
  };

  return data ? (
    <Wrapper background="neutral">
      <Container kind="narrow" justifyContent="flex-start">
        <Title element="h3" size="h5">
          {data.title}
        </Title>
        {data.testimonials.map(testimonial => (
          <Content key={testimonial.client}>
            <a
              href={testimonial.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Figure>
                <Image
                  src={images[testimonial.client]}
                  alt="Testimonial from our client"
                  size={110}
                />
              </Figure>
            </a>
            <Header>
              <Text size="large">{testimonial.text}</Text>
            </Header>
          </Content>
        ))}
      </Container>
    </Wrapper>
  ) : null;
};

Testimonials.propTypes = {
  /**
   * Data passed from Contenetful
   */
  data: PropTypes.object,
};

Testimonials.defaultProps = {
  data: null,
};

export default Testimonials;
