/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PlainPage, Heading, Link } from '../components';
import { mb20 } from '../common/classes';

/**
 * Not Found page
 */
const NotFound = () => (
  <PlainPage>
    <Heading element="h1" css={mb20}>
      404 Page Not Found
    </Heading>
    <Link to="/">← Back to Homepage</Link>
  </PlainPage>
);

export default NotFound;
