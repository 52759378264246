import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import { rem } from '../../common/utils';

const baseLinkStyles = ({ theme, kind }) =>
  css`
    color: ${theme.link[kind].color};
    transition: ${theme.transition.default};
    text-decoration: none;
    font-size: ${rem(theme.font.size.default)};
    text-decoration: ${kind === 'secondary' ? 'underline' : 'none'};
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    display: inline-block;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);

    &:active,
    &:focus,
    &:hover {
      color: ${theme.link[kind].hover};
      text-decoration: none;
    }

    svg {
      transition: ${theme.transition.default};
      fill: currentcolor;
      position: relative;
    }
  `;

const buttonLinkStyles = () =>
  css`
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    text-rendering: geometricPrecision;
  `;

const weightStyles = ({ theme, weight }) =>
  weight &&
  css`
    font-family: ${theme.font[weight]};
  `;

const PlainLinkStyles = styled('button')(
  baseLinkStyles,
  buttonLinkStyles,
  weightStyles,
);

const AnchorStyles = styled('a')(baseLinkStyles, weightStyles);

const RouterLinkStyles = styled(RouterLink)(baseLinkStyles, weightStyles);

/**
 * Link component
 */
const Link = ({
  to,
  onClick = () => {},
  kind = 'primary',
  weight = 'regular',
  ...props
}) => {
  if (!to)
    return (
      <PlainLinkStyles
        onClick={onClick}
        kind={kind}
        weight={weight}
        {...props}
      />
    );

  return to.match(/^https?:\/\//) || to.match(/mailto:/) ? (
    <AnchorStyles href={to} kind={kind} weight={weight} {...props} />
  ) : (
    <RouterLinkStyles to={to} kind={kind} weight={weight} {...props} />
  );
};

Link.propTypes = {
  /**
   * Define path to next screen, or page
   */
  to: PropTypes.string,
  /**
   * Provide option to pass onClick event handler
   */
  onClick: PropTypes.func,
  /**
   * Define link color
   */
  kind: PropTypes.oneOf(['primary', 'secondary']),
  /**
   * Define font weight
   */
  weight: PropTypes.oneOf(['regular', 'semiBold']),
};

Link.defaultProps = {
  to: '',
  onClick: () => {},
  kind: 'primary',
  weight: 'regular',
};

export default Link;
