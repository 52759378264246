import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = css`
  width: 100%;
`;

/**
 * Row component
 */
const Row = styled('div')(baseStyles);

export default Row;
