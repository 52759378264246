// Naming convention → https://material.io/tools/color/
const color = {
  white: '#fff',
  offWhite: '#fbfcff',
  black: '#000',

  // Blue shades
  blueAccent400: '#2f7aff',
  blueGrey900: '#272f3f',
  blueGrey800: '#303847',
  blueGrey400: '#7d889b',
  blueGrey100: '#d5d9e1',
  blueGrey50: '#f1f3f7',
  blue50: '#f1f6ff',

  // Grey shades
  grey900: '#16161d',

  // Earth shades
  red400: '#e75a70',
  green400: '#81c784',

  // Transparents
  blueTransparent20: 'rgba(80, 192, 255, 0.2)',
};

const theme = {
  name: 'light',

  accent: {
    brand: color.blueAccent400,
    neutral: color.blueGrey400,
    border: color.blueGrey100,
    borderLight: color.blueGrey50,
    white: color.white,
    lightAccent: color.blue50,
  },

  contact: {
    features: color.blue50,
    footer: color.blueGrey900,
  },

  scaffolding: {
    color: color.grey900,
    background: color.offWhite,
    selection: color.blueTransparent20,
  },

  section: {
    light: color.offWhite,
    neutral: color.blueGrey50,
    dark: color.blueGrey800,
  },

  form: {
    color: color.grey900,
    placeholder: color.blueGrey400,
    border: color.blueGrey100,
    active: color.blueAccent400,
    error: color.red400,
    background: color.white,
    footer: color.blueGrey50,
  },

  button: {
    kind: {
      primary: {
        initial: color.blueAccent400,
        hover: color.grey900,
        focusRing: color.blueAccent400,
        active: color.blueAccent400,
        color: color.white,
        colorHover: color.white,
      },
      secondary: {
        color: color.blueAccent400,
        focusRing: color.blueAccent400,
        colorHover: color.grey900,
        border: color.blueAccent400,
        borderHover: color.grey900,
      },
    },
    size: {
      mini: {
        fontSize: 14,
        padding: '12px 16px',
      },
      default: {
        fontSize: 16,
        padding: '18px 26px',
      },
    },
  },

  link: {
    primary: {
      color: color.blueAccent400,
      hover: color.grey900,
    },
    secondary: {
      color: color.blueGrey400,
      hover: color.blueAccent400,
    },
  },

  font: {
    regular:
      '"Inter Regular", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif',
    semiBold:
      '"Inter Semibold", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif',
    bold:
      '"Inter Bold", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif',
    size: {
      mini: 14,
      default: 16,
      medium: 18,
      large: 22,
    },
    color: {
      neutral: color.blueGrey400,
      text: color.grey900,
      light: color.white,
      brand: color.blueAccent400,
    },
    heading: {
      h1: {
        fontSize: 48,
        fontSizeMobile: 32,
        lineHeight: 1.1,
      },
      h2: {
        fontSize: 36,
        fontSizeMobile: 22,
        lineHeight: 1.3,
      },
      h3: {
        fontSize: 28,
        fontSizeMobile: 22,
        lineHeight: 1.5,
      },
      h4: {
        fontSize: 18,
        lineHeight: 1.5,
      },
      h5: {
        fontSize: 16,
        lineHeight: 1.5,
      },
      h6: {
        fontSize: 14,
        lineHeight: 1.5,
      },
    },
  },

  grid: {
    breakpoints: {
      mobile: 480,
      tablet: 768,
      desktop: 990,
      hd: 1600,
    },
    container: {
      default: '1180px',
      narrow: '768px',
    },
  },

  radius: {
    default: '4px',
    medium: '6px',
    circle: '100%',
  },

  transition: {
    default: '0.1s all ease-in-out',
    medium: '0.3s all ease-in-out',
    large: '0.5s all ease-in-out',
  },

  boxShadow: {
    small: '0 0 10px rgba(0, 0, 0, 0.05);',
    initial: '0 0 15px rgba(0, 0, 0, 0.15);',
    raised: '0 4px 15px rgba(0, 0, 0, 0.15);',
  },
};

export default theme;
