import * as React from 'react';
import PropTypes from 'prop-types';
import { SectionLink } from 'react-scroll-section';
import { Heading, Icon, Text } from '../../components';
import {
  Wrapper,
  Header,
  OverviewList,
  OverviewItem,
  Subheading,
  ScrollToLink,
} from './Overview.styles';

const icons = [
  'drag-and-drop',
  'devices',
  'bolt',
  'record',
  'configure',
  'security',
  'connection',
  'users',
  'loupe',
  'transfer',
];

/**
 * Overview section
 */
const Overview = ({ data }) => {
  return data ? (
    <Wrapper>
      <Header>
        <Heading element="h2">{data.title}</Heading>
        <Subheading size="large">{data.subtitle}</Subheading>
      </Header>
      <OverviewList>
        {data.functionalities.map((item, index) => (
          <OverviewItem key={item.name}>
            <Icon kind={icons[index]} size={36} color="#7d889b" />
            <Text weight="semiBold">{item.name}</Text>
          </OverviewItem>
        ))}
      </OverviewList>
      <SectionLink section="use-cases">
        {link => (
          <ScrollToLink onClick={link.onClick} weight="semiBold">
            {data.scrollToUseCasesLink}
            <Icon kind="arrow-right" size={16} />
          </ScrollToLink>
        )}
      </SectionLink>
    </Wrapper>
  ) : null;
};

Overview.propTypes = {
  /**
   * Data passed from Contenetful
   */
  data: PropTypes.object,
};

Overview.defaultProps = {
  data: null,
};

export default Overview;
