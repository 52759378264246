/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { SectionLink } from 'react-scroll-section';
import { Image, Heading, Text, Column, Carousel } from '../../components';
import {
  Wrapper,
  Header,
  Subtitle,
  Footer,
  Platforms,
  ButtonWrapper,
  PrimaryButton,
} from './Intro.styles';
import { showMinDesktop } from '../../common/classes';
import platforms from '../../assets/images/platforms.svg';

/**
 * Intro section
 */
const Intro = ({ setCurrentTab, data }) => {
  return data ? (
    <Wrapper>
      <Column span={[12, 12, 8, 6]}>
        <Header>
          <Heading element="h1">{data.title}</Heading>
          <Subtitle color="neutral" size="large">
            {data.subtitle}
          </Subtitle>
        </Header>
        <Footer>
          <ButtonWrapper>
            <SectionLink section="contact">
              {link => (
                <PrimaryButton onClick={link.onClick}>
                  {data.primaryButton}
                </PrimaryButton>
              )}
            </SectionLink>
          </ButtonWrapper>
          <Platforms>
            <Text color="neutral">Works wherever you are</Text>
            <Image
              src={platforms}
              size={140}
              alt="R Desktop is supported on all major platforms"
            />
          </Platforms>
        </Footer>
      </Column>
      <Column span={[6]} css={showMinDesktop}>
        <Carousel data={data} setCurrentTab={setCurrentTab} />
      </Column>
    </Wrapper>
  ) : null;
};

Intro.propTypes = {
  /**
   * Data passed from Contenetful
   */
  data: PropTypes.object,
  /**
   * Set active tab on use cases section
   */
  setCurrentTab: PropTypes.func.isRequired,
};

Intro.defaultProps = {
  data: null,
};

export default Intro;
