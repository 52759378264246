import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { inputStyles, errorStyles } from '../Input/Input';

export const textareaStyles = css`
  max-width: 100%;
  min-width: 100%;
  min-height: 100px;
`;

const TextareaStyles = styled('textarea')(
  inputStyles,
  textareaStyles,
  errorStyles,
);

/**
 * Textarea component
 */
const Textarea = ({ error = false, ...props }) => (
  <TextareaStyles error={error} {...props} />
);

Textarea.propTypes = {
  error: PropTypes.bool,
};

Textarea.defaultProps = {
  error: false,
};

export default Textarea;
