import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { rem } from '../../common/utils';

export const inputStyles = ({ theme }) =>
  css`
    font-size: ${rem(theme.font.size.default)};
    font-family: ${theme.font.regular};
    color: ${theme.form.color};
    display: block;
    width: 100%;
    padding: 16px 12px;
    border: 0;
    border-radius: ${theme.radius.default};
    border: 2px solid ${theme.form.border};
    box-shadow: ${theme.boxShadow.small};
    transition: 0.1s all ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    ::placeholder {
      color: ${theme.form.placeholder};
      opacity: 1;
    }
    ::-moz-placeholder {
      color: ${theme.form.placeholder};
      opacity: 1;
    }
    ::-ms-input-placeholder {
      color: ${theme.form.placeholder};
    }
    :-ms-input-placeholder {
      color: ${theme.form.placeholder};
    }
    &:focus {
      outline: none;
      border-color: ${theme.form.active};
      box-shadow: 0 0 0 1px ${theme.form.active};
    }
  `;

export const errorStyles = ({ error, theme }) =>
  error &&
  css`
    color: ${theme.form.error};
  `;

const InputStyles = styled('input')(inputStyles, errorStyles);

/**
 * Input component
 */
const Input = ({ error = false, ...props }) => (
  <InputStyles error={error} {...props} />
);

Input.propTypes = {
  error: PropTypes.bool,
};

Input.defaultProps = {
  error: false,
};

export default Input;
