import React from 'react';
import Link from '../Link';
import Image from '../Image';
import logo from '../../assets/images/logo.svg';

/**
 * Logo component
 */
const Logo = props => (
  <Link to="/" aria-label="Logo of R Desktop web site" {...props}>
    <Image src={logo} alt="R Desktop Logo" />
  </Link>
);

export default Logo;
