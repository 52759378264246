import { useState, useEffect } from 'react';
import * as contentful from 'contentful';

/**
 * useFetch hook
 */
const useFetch = () => {
  const [sections, setSections] = useState(undefined);

  const defineSections = arr => {
    const obj = {};

    arr.map(item => {
      const id = item.sys.contentType.sys.id;
      const { fields } = item;

      return (obj[id] = fields);
    });

    setSections(obj);
  };

  useEffect(() => {
    contentful
      .createClient({
        space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
        accessToken: process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN,
      })
      .getEntries()
      .then(res => defineSections(res.items))
      .catch(console.error);
  }, []);

  return { sections };
};

export default useFetch;
