/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Heading, Image, Column } from '../../components';
import {
  Wrapper,
  Content,
  Text,
  CardList,
  Card,
  CardName,
  Pictogram,
} from './Applications.styles';
import { ma } from '../../common/classes';
import pictogram from '../../assets/images/pictogram.svg';

// Read all icons from applications folder
function importAll(r) {
  const img = {};

  r.keys().map((item, index) => {
    img[index] = r(item);
    
    return null;
  });

  return img;
}

const applicationLogos = importAll(
  require.context('../../assets/applications', false, /\.(png|jpe?g|svg)$/),
);

const applicationNames = [
  'Adobe Illustrator',
  'Adobe Photoshop',
  'Adobe inDesign',
  'Google Chrome',
  'Firefox',
  'Microsoft Edge',
  'Safari',
  'Microsoft Word',
  'Microsoft Excel',
  'Microsoft PowerPoint',
  'Visual Studio Code',
  'Docker',
  'Microsoft Azure',
  'Dropbox',
  'Google Drive',
  'Microsoft OneNote',
  'Skype',
  'Slack',
  'Thunderbird',
  'Eclipse',
  'IntelliJ IDEA',
  'Lucidchart',
  'MySQL',
  'SQL Developer',
];

// Due to animation configuration, duplication of data is required
const duplicateLogos = [...Object.values(applicationLogos)].concat(
  Object.values(applicationLogos),
);
const duplicateNames = [...applicationNames].concat(applicationNames);

/**
 * Appliications section
 */
const Applications = ({ data }) => {
  const document = {
    nodeType: 'document',
    data: {},
    content: data && data.description.content[0].content,
  };

  return data ? (
    <Wrapper background="neutral">
      <Column span={[12, 12, 12, 6]}>
        <Content>
          <Heading element="h3">{data.title}</Heading>
          <Text size="large">{documentToReactComponents(document)}</Text>
        </Content>
      </Column>
      <Column span={[12, 12, 12, 6]}>
        <Pictogram size={220} src={pictogram} alt="R Desktop logo" />
        <CardList>
          {duplicateNames.map((_, index) => (
            <Card key={index}>
              <Image
                css={ma}
                src={duplicateLogos[index]}
                alt={duplicateNames[index]}
                size={64}
              />
              <CardName>{duplicateNames[index]}</CardName>
            </Card>
          ))}
        </CardList>
      </Column>
    </Wrapper>
  ) : null;
};

Applications.propTypes = {
  /**
   * Data passed from Contenetful
   */
  data: PropTypes.object,
};

Applications.defaultProps = {
  data: null,
};

export default Applications;
