import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash.get';
import useFetch from '../common/useFetch';
import { PlainPage, Heading } from '../components';

/**
 * Terms page
 */
const Terms = () => {
  const { sections } = useFetch();
  const data = get(sections, 'terms');
  const document = {
    nodeType: 'document',
    data: {},
    content: data && data.content && data.content.content,
  };

  return data ? (
    <PlainPage>
      <Heading element="h1">{data.title}</Heading>
      {documentToReactComponents(document)}
    </PlainPage>
  ) : null;
};

export default Terms;
