import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = ({ kind, justifyContent, alignItems, theme }) =>
  css`
    display: flex;
    width: 100%;
    max-width: ${theme.grid.container[kind]};
    justify-content: ${justifyContent};
    align-items: ${alignItems};
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    position: relative;
    padding: 0 15px;
  `;

const ContainerStyles = styled('div')(baseStyles);

/**
 * Container component
 */
const Container = ({
  kind = 'default',
  justifyContent = 'space-between',
  alignItems = 'stretch',
  ...props
}) => (
  <ContainerStyles
    kind={kind}
    justifyContent={justifyContent}
    alignItems={alignItems}
    {...props}
  />
);

Container.propTypes = {
  /**
   * Define container width
   */
  kind: PropTypes.oneOf(['narrow', 'default']),
  /**
   * Define container width
   */
  justifyContent: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
    'initial',
    'inherit',
  ]),
  /**
   * Define container width
   */
  alignItems: PropTypes.oneOf([
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'baseline',
    'initial',
    'inherit',
  ]),
};

Container.defaultProps = {
  kind: 'default',
  justifyContent: 'space-between',
  alignItems: 'stretch',
};

export default Container;
