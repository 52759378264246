import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { min } from '../../../common/utils';
import { Section, Text } from '../../../components';

const wrapperStyles = css`
  padding: 60px 0;
  text-align: center;
  ${min(
    'tablet',
    `
      padding: 90px 0 100px;
    `,
  )}
`;

const headerStyles = css`
  margin: 0 auto 50px;
  ${min(
    'tablet',
    `
      max-width: 670px;
    `,
  )}
`;

const subheadingStyles = css`
  margin-top: 20px;
  margin-bottom: 0;
`;

/**
 * Components declaration
 */
export const Wrapper = styled(Section)(wrapperStyles);

export const Header = styled('header')(headerStyles);

export const Subheading = styled(Text)(subheadingStyles);
