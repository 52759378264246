import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Responsively from 'responsively';

const baseStyles = ({ span, alignSelf }) => {
  const values = span.map(item => {
    if (item === 12) {
      return `calc(${item} * 8.333333%)`;
    }

    return `calc(${item} * 8.333333% - 15px)`;
  });

  return css`
    position: relative;
    align-self: ${alignSelf};
    ${Responsively.responsive('width')([...values])};
  `;
};

/**
 * Column component
 */
const ColumnStyles = styled('div')(baseStyles);

const Column = ({ span = [12 | undefined], alignSelf = 'auto', ...props }) => (
  <ColumnStyles span={span} alignSelf={alignSelf} {...props} />
);

Column.propTypes = {
  /**
   * Define column width
   */
  span: PropTypes.any,
  /**
   * Align self
   */
  alignSelf: PropTypes.string,
};

Column.defaultProps = {
  span: [12],
  alignSelf: 'auto',
};

export default Column;
