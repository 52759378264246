/** @jsx jsx */
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { SectionLink } from 'react-scroll-section';
import { Container } from '../Grid';
import Logo from '../Logo';
import { Button } from '../Button';

const navigationStyles = css`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
  margin-top: 30px;
  background: rgba(251, 252, 255, 0.9);
  padding: 15px 0;
`;

const NavigationStyles = styled('header')(navigationStyles);

/**
 * Navigation component
 */
const Navigation = ({ data }) => {
  return data ? (
    <NavigationStyles>
      <Container alignItems="center">
        <Logo />
        <nav>
          <SectionLink section="contact">
            {link => (
              <Button size="mini" onClick={link.onClick}>
                {data.primaryButton}
              </Button>
            )}
          </SectionLink>
        </nav>
      </Container>
    </NavigationStyles>
  ) : null;
};

Navigation.propTypes = {
  /**
   * Data passed from Contenetful
   */
  data: PropTypes.object,
};

Navigation.defaultProps = {
  data: null,
};

export default Navigation;
