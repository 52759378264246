import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';
import { min, max, rem } from '../../common/utils';
import { Section, Text as T, Image } from '../../components';

const wrapperStyles = ({ theme }) =>
  css`
    display: flex;
    width: 100%;
    margin: 0 auto;
    background: ${theme.section.neutral};
    position: relative;
    overflow: hidden;
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      height: 40px;
      z-index: 40;
    }
    &:after {
      bottom: 0;
      box-shadow: inset 0 -6px 6px -6px rgba(0, 0, 0, 0.07);
    }
    &:before {
      top: 0;
      box-shadow: inset 0 6px 6px -6px rgba(0, 0, 0, 0.07);
    }
  `;

const contentStyles = css`
  padding: 60px 0;
  ${min(
    'desktop',
    `
      padding: 100px 100px 100px 0;
    `,
  )}
`;

const headerStyles = css`
  margin: 10px 0 0;
`;

const textStyles = css`
  margin: 20px 0 0;
  line-height: 1.8;
`;

const pictogramStyles = ({ theme }) =>
  css`
    filter: drop-shadow(0 4px 15px rgba(0, 0, 0, 0.25));
    position: absolute;
    margin: auto;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    ${max(
      'tablet',
      `
        width: 150px;
        height: 150px;
      `,
    )}
  `;

const scrollUp = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, -1600px, 0);
  }
`;

const scrollLeft = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-1620px, 0, 0);
  }
`;

const cardListStyles = ({ theme }) =>
  css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 500px;
    transform: translateX(0);
    transform-style: preserve-3d;
    animation: ${scrollUp} 40s linear infinite;
    @media screen and (max-width: ${theme.grid.breakpoints.desktop / 16}em) {
      width: 3240px;
      height: 390px;
      animation: ${scrollLeft} 40s linear infinite;
    }
  `;

const cardStyles = ({ theme }) =>
  css`
    width: calc(33.333% - 15px);
    min-height: 180px;
    border-radius: ${theme.radius.medium};
    background: ${theme.accent.white};
    box-shadow: ${theme.boxShadow.small};
    margin: 0 0 20px;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-between;
    ${max(
      'desktop',
      `
        margin: 0 15px 15px 0;
        width: 150px;
      `,
    )}
  `;

const cardNameStyles = ({ theme }) =>
  css`
    border-top: 1px solid ${theme.accent.borderLight};
    padding: 8px 5px;
    text-align: center;
    color: ${theme.font.color.neutral};
    font-size: ${rem(theme.font.size.mini)};
  `;

export const Wrapper = styled(Section)(wrapperStyles);

export const Content = styled('div')(contentStyles);

export const Header = styled('header')(headerStyles);

export const Text = styled(T)(textStyles);

export const Pictogram = styled(Image)(pictogramStyles);

export const CardList = styled('div')(cardListStyles);

export const Card = styled('figure')(cardStyles);

export const CardName = styled('span')(cardNameStyles);
