import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { rem, hexToRgb } from '../../common/utils';
import ButtonBase from './components/ButtonBase';

const sizeStyles = ({ size, theme: { button } }) => css`
  font-size: ${rem(button.size[size].fontSize)};
  padding: ${button.size[size].padding};
`;

const outlineStyles = ({ theme: { button } }) =>
  css`
    color: ${button.kind.secondary.color};
    border: 2px solid ${button.kind.secondary.border};
    background: transparent;
    &:hover {
      color: ${button.kind.secondary.colorHover};
      border-color: ${button.kind.secondary.borderHover};
    }

    &:active,
    &:focus {
      color: ${button.kind.secondary.colorHover};
      border-color: ${button.kind.secondary.border};
      box-shadow: 0 0 0 0.2rem ${hexToRgb(button.kind.secondary.focusRing, 0.4)};
    }
  `;

const ButtonStyles = styled(ButtonBase)(sizeStyles, outlineStyles);

/**
 * Button Outline component
 */
const ButtonOutline = ({ size = 'default', ...props }) => (
  <ButtonStyles size={size} {...props} />
);

ButtonOutline.propTypes = {
  /**
   * ButtonOutline size
   */
  size: PropTypes.oneOf(['default', 'mini']),
};

ButtonOutline.defaultProps = {
  size: 'default',
};

export default ButtonOutline;
