import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { Global } from '@emotion/core';
import theme from '../../common/theme';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { scaffoldingStyles } from '../../common/scaffoldingStyles';
import NavigationAlternate from '../Navigation/NavigationAlternate';
import { Container, Column } from '../Grid';
import { rem } from '../../common/utils';

const contentStyles = ({ theme }) =>
  css`
    margin: 90px 0;

    a {
      color: ${theme.link.primary.color};
      transition: ${theme.transition.default};
      text-decoration: none;
      font-size: ${rem(theme.font.size.default)};
      text-decoration: none;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      outline: none;
      display: inline-block;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);

      &:active,
      &:focus,
      &:hover {
        color: ${theme.link.primary.hover};
        text-decoration: none;
      }
    }
  `;

const Content = styled('main')(contentStyles);

/**
 * PlainPage page
 */
const PlainPage = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Fragment>
      <NavigationAlternate />
      <Content>
        <Container>
          <Column span={[12]}>
            <Global styles={scaffoldingStyles} />
            {children}
          </Column>
        </Container>
      </Content>
    </Fragment>
  </ThemeProvider>
);

PlainPage.propTypes = {
  /**
   * Content which will be passed
   */
  children: PropTypes.node.isRequired,
};

export default PlainPage;
