import React from 'react';
import PropTypes from 'prop-types';
import { Section } from 'react-scroll-section';
import { Heading } from '../../components';
import {
  Wrapper,
  Header,
  Subheading,
  TabNavigation,
  TabContent,
} from './components';

/**
 * Use cases section
 */
const UseCases = ({ data, currentTab, setCurrentTab }) => {
  return data ? (
    <Section id="use-cases">
      <Wrapper>
        <Header>
          <Heading element="h2">{data.title}</Heading>
          <Subheading size="large">{data.subtitle}</Subheading>
        </Header>
        <TabNavigation
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          categories={data.categories}
        />
        <TabContent
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          categories={data.categories}
        />
      </Wrapper>
    </Section>
  ) : null;
};

UseCases.propTypes = {
  /**
   * Data passed from Contenetful
   */
  data: PropTypes.object,
  /**
   * Indicate which tab is active on use case section
   */
  currentTab: PropTypes.number.isRequired,
  /**
   * Set active tab on use cases section
   */
  setCurrentTab: PropTypes.func.isRequired,
};

UseCases.defaultProps = {
  data: null,
};

export default UseCases;
