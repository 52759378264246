import { css } from '@emotion/core';
import theme from './theme';
import { rem } from './utils';

export const scaffoldingStyles = css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    font-size: ${rem(theme.font.size.default)};
    font-family: ${theme.font.regular};
    line-height: 1.5;
    color: ${theme.scaffolding.color};
    background-color: ${theme.scaffolding.background};
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-kerning: normal;
    font-variant-ligatures: common-ligatures, contextual;
    font-variant-numeric: oldstyle-nums, proportional-nums;
    font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    overflow-x: hidden;
    margin: 0;
  }

  ::-moz-selection {
    background: ${theme.scaffolding.selection};
    color: ${theme.scaffolding.color};
  }

  ::selection {
    background: ${theme.scaffolding.selection};
    color: ${theme.scaffolding.color};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;

    .wf-loading & {
      font-weight: bold;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  strong {
    font-family: ${theme.font.semiBold};
  }
`;
