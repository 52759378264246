import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { rem, hexToRgb } from '../../common/utils';
import ButtonBase from './components/ButtonBase';

const sizeStyles = ({ size, theme: { button } }) => css`
  font-size: ${rem(button.size[size].fontSize)};
  padding: ${button.size[size].padding};
`;

const buttonStyles = ({ theme: { button } }) => css`
  color: ${button.kind.primary.color};
  background: ${button.kind.primary.initial};
  &:hover {
    color: ${button.kind.primary.colorHover};
    background: ${button.kind.primary.hover};
  }
  &:active,
  &:focus {
    color: ${button.kind.primary.color};
    background: ${button.kind.primary.active};
    box-shadow: 0 0 0 0.2rem ${hexToRgb(button.kind.primary.focusRing, 0.5)};
  }
`;

const ButtonStyles = styled(ButtonBase)(sizeStyles, buttonStyles);

/**
 * Button component
 */
const Button = ({ size = 'default', ...props }) => (
  <ButtonStyles size={size} {...props} />
);

Button.propTypes = {
  /**
   * Button size
   */
  size: PropTypes.oneOf(['default', 'mini']),
};

Button.defaultProps = {
  size: 'default',
};

export default Button;
