import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { rem, max } from '../../common/utils';

const baseStyles = ({ theme, element }) => css`
  font-family: ${theme.font.semiBold};
  font-size: ${rem(theme.font.heading[element].fontSize)};
  line-height: ${theme.font.heading[element].lineHeight};
  color: ${theme.font.color.text};
  margin: 0;
`;

const sizeStyles = ({ theme, element, size = element }) =>
  size &&
  css`
    font-size: ${rem(theme.font.heading[size].fontSize)};
    line-height: ${theme.font.heading[size].lineHeight};
    ${theme.font.heading[size].fontSizeMobile &&
      max(
        'mobile',
        `
          font-size: ${rem(theme.font.heading[size].fontSizeMobile)};
        `,
      )}
  `;

const colorStyles = ({ theme, color }) =>
  color &&
  css`
    color: ${theme.font.color[color]};
  `;

/**
 * Heading component
 */
const Heading = styled(
  ({ children, element, size = element, color, ...props }) =>
    React.createElement(`${element}`, props, children),
)(baseStyles, sizeStyles, colorStyles);

Heading.propTypes = {
  /**
   * Children which will be passed
   */
  children: PropTypes.node.isRequired,
  /**
   * What HTML element should be rendered
   */
  element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
  /**
   * What font size and line height should be applied to the rendered element
   */
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  /**
   * Define heading color
   */
  color: PropTypes.oneOf(['text', 'neutral', 'light']),
};

Heading.defaultProps = {
  color: null,
};

export default Heading;
