import * as React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Heading, Column, Image } from '../../components';
import { Wrapper, Feature, Figure, FeatureText } from './KeyFeatures.styles';
import featureOne from '../../assets/images/apache-guacamole.png';
import featureTwo from '../../assets/images/browsers.svg';
import featureThree from '../../assets/images/lock.svg';

/**
 * Key Features section
 */
const KeyFeatures = ({ data }) => {
  const documentOne = {
    nodeType: 'document',
    data: {},
    content: data && data.featureOneRichDescription.content[0].content,
  };

  const documentTwo = {
    nodeType: 'document',
    data: {},
    content: data && data.featureTwoRichDescription.content[0].content,
  };

  const documentThree = {
    nodeType: 'document',
    data: {},
    content: data && data.featureThreeRichDescription.content[0].content,
  };

  return data ? (
    <Wrapper background="neutral">
      <Column span={[12, 12, 4]}>
        <Feature>
          <Figure>
            <Image
              src={featureOne}
              alt="R Desktop is powered by Apache Guacamole"
              size={72}
            />
          </Figure>
          <Heading element="h3" size="h4">
            {data.featureOneTitle}
          </Heading>
          <FeatureText>{documentToReactComponents(documentOne)}</FeatureText>
        </Feature>
      </Column>
      <Column span={[12, 12, 4]}>
        <Feature>
          <Figure>
            <Image
              src={featureTwo}
              alt="R Desktop only needs browser to be used, no plugins are required"
              size={72}
            />
          </Figure>
          <Heading element="h3" size="h4">
            {data.featureTwoTitle}
          </Heading>
          <FeatureText>{documentToReactComponents(documentTwo)}</FeatureText>
        </Feature>
      </Column>
      <Column span={[12, 12, 4]}>
        <Feature>
          <Figure>
            <Image
              src={featureThree}
              alt="R Desktop provides secure, centralized access"
              size={54}
            />
          </Figure>
          <Heading element="h3" size="h4">
            {data.featureThreeTitle}
          </Heading>
          <FeatureText>{documentToReactComponents(documentThree)}</FeatureText>
        </Feature>
      </Column>
    </Wrapper>
  ) : null;
};

KeyFeatures.propTypes = {
  /**
   * Data passed from Contenetful
   */
  data: PropTypes.object,
};

KeyFeatures.defaultProps = {
  data: null,
};

export default KeyFeatures;
