import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { rem, max } from '../../common/utils';

const baseStyles = () =>
  css`
    margin-top: 0;
    margin-bottom: 0.5rem;
  `;

const sizeStyles = ({ theme, size }) =>
  size &&
  css`
    font-size: ${rem(theme.font.size[size])};
    ${size === 'large' &&
      max(
        'mobile',
        `
          font-size: ${rem(theme.font.size.medium)};
        `,
      )}
  `;

const weightStyles = ({ theme, weight }) =>
  weight &&
  css`
    font-family: ${theme.font[weight]};
  `;

const colorStyles = ({ theme, color }) =>
  color &&
  css`
    color: ${theme.font.color[color]};
  `;

const TextStyles = styled('p')(
  baseStyles,
  sizeStyles,
  weightStyles,
  colorStyles,
);

/**
 * Text component
 */
const Text = ({
  size = 'default',
  weight = 'regular',
  color = 'text',
  ...props
}) => <TextStyles size={size} weight={weight} color={color} {...props} />;

Text.propTypes = {
  /**
   * Define font size
   */
  size: PropTypes.oneOf(['mini', 'default', 'medium', 'large']),
  /**
   * Define font weight
   */
  weight: PropTypes.oneOf(['regular', 'semiBold']),
  /**
   * Define text color
   */
  color: PropTypes.oneOf(['neutral', 'text', 'light']),
};

Text.defaultProps = {
  size: 'default',
  weight: 'regular',
  color: 'text',
};

export default Text;
