/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import { max, min, hexToRgb } from '../../../common/utils';
import { showMinTablet } from '../../../common/classes';
import {
  Icon,
  Text,
  Image,
  Column,
  Heading,
  Check,
  Button,
} from '../../../components';
import learning from '../../../assets/images/use-cases/learning.jpg';
import government from '../../../assets/images/use-cases/government.jpg';
import healthcare from '../../../assets/images/use-cases/medicine.jpg';
import itSupport from '../../../assets/images/use-cases/it-support.jpg';

const images = [
  <Image src={government} alt="Government" />,
  <Image src={learning} alt="E-Learning" />,
  <Image src={itSupport} alt="IT Support" />,
  <Image src={healthcare} alt="Healthcare" />,
];

const contentWrapperStyles = ({ theme, isActive }) => css`
  margin-top: 60px;
  width: 100%;
`;

const contentStyles = ({ theme, isActive }) => css`
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const descriptionStyles = ({ theme }) => css`
  margin: 10px 0 30px;
`;

const listStyles = ({ theme }) => css`
  list-style-type: none;
  margin: 0;
  padding: 0;
  ${min(
    'mobile',
    `
      max-width: 360px;
    `,
  )}
`;

const itemStyles = ({ theme }) => css`
  display: flex;
  padding: 20px 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.accent.border};
  }
`;

const footerStyles = ({ theme }) => css`
  display: flex;
  width: 100%;
  margin-top: 60px;
  ${min(
    'mobile',
    `
      max-width: 360px;
    `,
  )}
  ${min(
    'tablet',
    `
      margin-top: 0;
      position: absolute;
      bottom: 0;
    `,
  )}
`;

const backButtonStyles = ({ theme: { button } }) => css`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 15px 14px 14px;
  background: ${button.kind.primary.hover};
  &:hover {
    background: ${button.kind.primary.initial};
  }
  &:active,
  &:focus {
    background: ${button.kind.primary.hover};
    box-shadow: 0 0 0 0.2rem ${hexToRgb(button.kind.primary.hover, 0.5)};
  }
`;

const primaryButtonStyles = css`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  text-align: left;

  svg {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    height: 100%;
  }
`;

const figureStyles = ({ theme, isActive }) => css`
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  border-radius: ${theme.radius.default};
  overflow: hidden;
  margin: 0;
  ${max(
    'hd',
    `
      height: 600px;
    `,
  )}
`;

const ContentWrapper = styled('div')(contentWrapperStyles);
const Content = styled('main')(contentStyles);
const Description = styled(Text)(descriptionStyles);
const List = styled('ul')(listStyles);
const Item = styled('li')(itemStyles);
const Footer = styled('footer')(footerStyles);
const BackButton = styled(Button)(backButtonStyles);
const PrimaryButton = styled(Button)(primaryButtonStyles);
const Figure = styled('div')(figureStyles);

/**
 * Tab content component
 */
const TabContent = ({ currentTab, setCurrentTab, categories }) => {
  const handleNextClick = () =>
    currentTab < 3 ? setCurrentTab(currentTab + 1) : setCurrentTab(0);
  const handlePreviousClick = () =>
    currentTab > 0 ? setCurrentTab(currentTab - 1) : setCurrentTab(3);
  const renderButtonText = index =>
    currentTab < 3 ? categories[index + 1].title : categories[0].title;

  return (
    <ContentWrapper>
      {[...new Array(4)].map(
        (_, index) =>
          currentTab === index && (
            <Content key={index}>
              <Column span={[12, 12, 6, 6]}>
                <Heading element="h3">{categories[index].title}</Heading>
                <Description size="large">
                  {categories[index].description}
                </Description>
                <List>
                  {categories[index].features.map(item => (
                    <Item key={item}>
                      <Check />
                      {item}
                    </Item>
                  ))}
                </List>
                <Footer>
                  <BackButton kind="secondary" onClick={handlePreviousClick}>
                    <Icon kind="chevron-left" size={20} color="#fff" />
                  </BackButton>
                  <PrimaryButton onClick={handleNextClick}>
                    Next: {renderButtonText(index)}
                    <Icon kind="chevron-right" size={20} color="#fff" />
                  </PrimaryButton>
                </Footer>
              </Column>
              <Column span={[12, 12, 6, 6]} css={showMinTablet}>
                <Figure>{images[index]}</Figure>
              </Column>
            </Content>
          ),
      )}
    </ContentWrapper>
  );
};

TabContent.propTypes = {
  /**
   * Indicate which tab is active on use case section
   */
  currentTab: PropTypes.number.isRequired,
  /**
   * Set active tab on use cases section
   */
  setCurrentTab: PropTypes.func.isRequired,
  /**
   * Data passed from Contentful
   */
  categories: PropTypes.array.isRequired,
};

TabContent.defaultProps = {
  data: null,
};

export default TabContent;
