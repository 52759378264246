import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = () =>
  css`
    display: block;
    width: 100%;
    height: auto;
  `;

const widthStyles = ({ size }) =>
  size &&
  css`
    max-width: ${size}px;
  `;

const ImageStyles = styled('img')(baseStyles, widthStyles);

/**
 * Image component
 */
const Image = ({ src, alt, size, ...props }) => (
  <ImageStyles src={src} alt={alt} size={size} {...props} />
);

Image.propTypes = {
  /**
   * Image source
   */
  src: PropTypes.string.isRequired,
  /**
   * Image alt text
   */
  alt: PropTypes.string.isRequired,
  /**
   * Image width
   */
  size: PropTypes.number,
};

Image.defaultProps = {
  size: null,
};

export default Image;
