/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Column } from '../../components';
import { Wrapper, DemoImage, DemoButton, Header, Title } from './Demo.styles';
import { df } from '../../common/classes';
import demoImage from '../../assets/images/demo.jpg';

/**
 * Demo section
 */
const Demo = ({ data }) => {
  return data ? (
    <Wrapper>
      <Column span={[12, 12, 6, 6]}>
        <DemoImage src={demoImage} size={500} alt="Try R Desktop demo" />
      </Column>
      <Column span={[12, 12, 6, 5]} css={df}>
        <Header>
          <Title element="h3">{data.title}</Title>
          <DemoButton to="/">{data.button}</DemoButton>
        </Header>
      </Column>
    </Wrapper>
  ) : null;
};

Demo.propTypes = {
  /**
   * Data passed from Contenetful
   */
  data: PropTypes.object,
};

Demo.defaultProps = {
  data: null,
};

export default Demo;
